import React from 'react'
import Form from '../models/Form'
import useModal from '../hooks/useModal'
import { toast } from 'react-toastify'
import useLocale from './useLocale'

function useForms ({action = 'get', paginate = true, requestOnMount = true} = {}) {
  const [ forms, setForms ] = React.useState([])
  const { showModal, openModal, closeModal } = useModal()
  const nameRef = React.useRef(null)
  const { l } = useLocale()

  const handleAdd = () => {
    const { value: name } = nameRef.current
    Form.save({ name })
      .then(res => {
        const config = JSON.parse(localStorage.getItem('table_forms'))
        nameRef.current.value = ''
        getForms({
          filters: config.filters,
          page: config.page,
          limit: config.limit,
        })
        closeModal()
      })
      .catch(err => console.log(err))
  }


  const getForms = React.useCallback(({page = 1, limit = 10, filters = {}} = {}) => {
    Form[action](filters['name'], page, paginate, limit)
      .then(res => setForms(res))
      .catch(err => toast.error(l('general_error')))
  }, [action, paginate])

  React.useEffect(() => {
    if (requestOnMount === false) return;

    getForms()
  }, [requestOnMount, getForms])

  const handleDelete = (id, page, limit, filters) => {
    Form.delete(id)
      .then(res => getForms({page, limit, filters}))
      .catch(err => toast.error(l('general_error')))
  }

  return {
    forms,
    handleDelete,
    nameRef,
    handleAdd,
    openModal,
    showModal,
    closeModal,
    getForms,
  }
}

export default useForms
