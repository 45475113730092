import React from 'react'
import Card from '../../components/Card'
import { Input } from '../../components/FormComponents'
import { H2, PageHeading } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import Modal from '../../components/Modal'
import useUser from '../../hooks/useUser'
import Button from '../../components/Button'
import CheckRole from '../../components/CheckRole'
import UserAttributes from '../../components/UserAttributes'
import UserRolesPermissions from '../../components/UserRolesPermissions'
import RememberPassword from '../../components/RememberPasswordForm'
import ChangeUserPassword from '../../components/ChangeUserPassword'
import UserSessions from '../../components/UserSessions'
import { Link } from 'react-router-dom'

function Show () {
  const { l } = useLocale()
  const {
    user,
    loading,
    handleChangeAttribute,
    handleDeleteAttribute,
    openModal,
    showModal,
    closeModal,
    attributeKeyRef,
    handleAddAttribute,
    save,
    handleChange,
    handleRoleSelect,
  } = useUser()

  if (loading === true) return null

  return (
    <>
      <PageHeading image='/img/users.svg' title={user.name}>
        <RememberPassword email={user.email} />
        <ChangeUserPassword userId={user._id} />
        <UserSessions userId={user._id} />
        <CheckRole has="view_users_points">
          <Link to={`/users/${user._id}/points`} className='px-2 py-1 rounded-md ease-in-out duration-200 disabled:opacity-50 bg-blue-700 hover:bg-blue-900'>
            {l('points')} ({user.points?.toLocaleString('es-ES', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })} pts)
          </Link>
        </CheckRole>
      </PageHeading>
      <Card>
        <div className='grid grid-cols-1 sm:grid-cols-2 sm:gap-9'>
          <Input
            label={l('name')}
            name='name'
            value={user.name}
            onChange={handleChange}
          />
          <Input
            label={l('email')}
            value={user.email}
            name='email'
            onChange={handleChange}
          />
        </div>
        <div className='grid grid-cols-2 gap-9'>
          <UserAttributes
            openModal={openModal}
            user={user}
            handleChangeAttribute={handleChangeAttribute}
            handleDeleteAttribute={handleDeleteAttribute}
          />
          <div>
            <UserRolesPermissions
              user={user}
              handleRoleSelect={handleRoleSelect}
            />
          </div>
        </div>
      </Card>
      <CheckRole has='edit_users'>
        <Button type='primary' onClick={save}>
          {l('save')}
        </Button>
      </CheckRole>
      <Modal show={showModal} onClose={closeModal}>
        <H2>{l('add_new_key')}</H2>
        <Input label={l('key')} reference={attributeKeyRef} name='key' />
        <Button type='secondary' full onClick={handleAddAttribute}>
          {l('add')}
        </Button>
      </Modal>
    </>
  )
}

export default Show
