import React from 'react'
import useLocale from './useLocale'
import useModal from './useModal'
import State from '../models/State'
import { toast } from 'react-toastify'

function useStates (paginate = true) {
  const { showModal, openModal, closeModal } = useModal()
  const nameRef = React.useRef(null)
  const colorRef = React.useRef(null)
  const labelRef = React.useRef(null)
  const editableRef = React.useRef(null)
  const addableRef = React.useRef(null)
  const omitRequiredRef = React.useRef(null)
  const downloadableRef = React.useRef(null)
  const canAssignQualityRef = React.useRef(null)
  const canAssignDifficultyRef = React.useRef(null)
  const isPublishedRef = React.useRef(null)
  const maskRef = React.useRef(null)
  const mustSendImagesToGalleryRef = React.useRef(null)
  const addAuthorCommentsRef = React.useRef(null)
  const [states, setStates] = React.useState([])
  const [editing, setEditing] = React.useState(null)
  const [search, setSearch] = React.useState('')
  const [page, setPage] = React.useState(1)
  const { l } = useLocale()

  const clearInputs = () => {
    nameRef.current.value = ''
    colorRef.current.value = ''
    labelRef.current.value = ''
    editableRef.current.checked = ''
    omitRequiredRef.current.checked = ''
    downloadableRef.current.checked = ''
    maskRef.current.value = ''
  }

  const getStates = () => {
    State.get(search, page, paginate)
      .then(res => setStates(res))
      .catch(err => toast.error(l('general_error')))
  }

  const handleSubmit = e => {
    e.preventDefault()
    const data = {
      name: nameRef.current.value,
      color: colorRef.current.value,
      label: labelRef.current.value,
      editable: editableRef.current.checked,
      canAddComments: addableRef.current.checked,
      canAddAuthorComments: addAuthorCommentsRef.current.checked,
      omitRequired: omitRequiredRef.current.checked,
      canDownload: downloadableRef.current.checked,
      canAssignDifficulty: canAssignDifficultyRef.current.checked,
      canAssignQuality: canAssignQualityRef.current.checked,
      isPublished: isPublishedRef.current.checked,
      mask: maskRef.current.value,
      mustSendImagesToGallery: mustSendImagesToGalleryRef.current.checked
    }
    if (editing !== null) {
      State.update(editing, data)
        .then(res => {
          toast.success(l('state_saved'))
          getStates()
          clearInputs()
          closeModal()
          setEditing(null)
        })
        .catch(err => toast.error(l('general_error')))
      return
    }

    State.save(data)
      .then(() => {
        toast.success(l('state_saved'))
        getStates()
        clearInputs()
        closeModal()
      })
      .catch(err => toast.error(l('general_error')))
  }

  React.useEffect(() => {
    getStates()
  }, [search, page])

  const handleEdit = id => {
    State.show(id)
      .then(res => {
        nameRef.current.value = res.name
        colorRef.current.value = res.color
        labelRef.current.value = res.label
        editableRef.current.checked = res.editable
        addableRef.current.checked = res.canAddComments
        omitRequiredRef.current.checked = res.omitRequired
        downloadableRef.current.checked = res.canDownload
        canAssignDifficultyRef.current.checked = res.canAssignDifficulty
        canAssignQualityRef.current.checked = res.canAssignQuality
        isPublishedRef.current.checked = res.isPublished
        maskRef.current.value = res.mask
        mustSendImagesToGalleryRef.current.value = res.mustSendImagesToGallery
        addAuthorCommentsRef.current.checked = res.canAddAuthorComments
        openModal()
        setEditing(id)
      })
      .catch(err => console.log(err))
  }

  const handleDelete = id => {
    State.delete(id)
      .then(res => {
        toast.success(l('state_deleted'))
        getStates()
      })
      .catch(err => toast.error(l('general_error')))
  }

  const handleAdd = () => {
    setEditing(null)
    clearInputs()
    openModal()
  }

  return {
    openModal,
    states,
    handleEdit,
    handleDelete,
    handleSubmit,
    showModal,
    closeModal,
    nameRef,
    colorRef,
    editableRef,
    labelRef,
    editing,
    handleAdd,
    addableRef,
    search,
    setSearch,
    page,
    setPage,
    omitRequiredRef,
    downloadableRef,
    canAssignDifficultyRef,
    canAssignQualityRef,
    isPublishedRef,
    mustSendImagesToGalleryRef,
    maskRef,
    addAuthorCommentsRef
  }
}

export default useStates
