import React from 'react'
import ClinicCase from '../models/ClinicCase'

const toggleRules = {
  mine: 'all',
  all: 'mine'
}

function useClinicCases (paginate = true) {
  const [clinicCases, setClinicCases] = React.useState([])
  const [viewMode, setViewMode] = React.useState('all')

  const getClinicCases = React.useCallback(function ({page, limit, filters}) {
    if (viewMode === 'mine')
      ClinicCase.my(filters['user.name'], filters['state.name'], filters['form.name'], page, paginate, limit, filters['title']).then(res => setClinicCases(res))
    if (viewMode === 'all')
      ClinicCase.get(filters['user.name'], filters['state.name'], filters['form.name'], page, paginate, limit, filters['title']).then(res => setClinicCases(res))
  }, [paginate, viewMode])

  const handleDelete = (id, page, limit) => {
    ClinicCase.delete(id).then(res => getClinicCases({page, limit}))
  }

  function toggleViewMode () {
    setViewMode(toggleRules[viewMode])
  }

  return {handleDelete, toggleViewMode, viewMode, getClinicCases, clinicCases}
}

export default useClinicCases
