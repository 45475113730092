import React from 'react'
import literals from '../config/literals.json'
import AppContext from '../contexts/AppContext'

export default function useLocale() {
	const { locale } = React.useContext(AppContext)

	const l = key => {
		return literals[locale][key] || key
	}

	return {
		l,
	}
}
