import React from 'react'
import {H2, H3} from '../Headings'
import Divider from '../../components/Divider'
import useLocale from '../../hooks/useLocale'
import Button from '../Button'

export const componentsMap = {
  section: Section,
  input: ShowValue,
  datetime: ShowValue,
  textarea: ShowValue,
  radios: ShowItemValue,
  editor: ShowHTML,
  multiple: ShowMultiple,
  select: ShowItemValue,
  tag: ShowMultiple,
  image_gallery_item: ShowValue,
  predefined_questions: ShowValue,
  title: ShowValue,
  file: ShowImage,
  optin: ShowOptin
}

function Layout ({title = null, html = null, children, cols}) {
  return (
    <div className='my-3' style={{gridColumn: `span ${cols} / span ${cols}`}}>
      {title && <H3>{title}</H3>}
      {html && <span dangerouslySetInnerHTML={{__html: html}}></span>}
      {children}
    </div>
  )
}

export function Section ({item, isAnswersActive = false, handleOpen}) {
  const isMultiple = item.attributes.is_multiple
  const {l} = useLocale()

  return (
    <div className='col-span-12 grid grid-cols-12 border p-5 rounded-md'>
      <H2 className="col-span-12">{item.attributes.title}</H2>
      {!isMultiple &&
        Object.keys(item.items).map(key => {
          const element = item.items[key]
          const hasQuestions = element.attributes.has_questions && isAnswersActive

          return React.createElement(componentsMap[element.component], {
            item: element,
            Component: hasQuestions ? <Button type='warning' onClick={() => handleOpen(element)}>
              {l('answer_questions')}
            </Button> : null
          })
        })}
      {isMultiple && Array.isArray(item.items) && (
        <>
          {item.items.map(item => {
            return (
              <div className="col-span-12 grid grid-cols-12 ">
                {Object.values(item ? item : {}).map(elem => {
                  return React.createElement(componentsMap[elem.component], {
                    item: elem,
                  })
                })}
                <Divider />
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}

export function ShowOptin ({item, Component}) {
  const {l} = useLocale()

  return (
    <Layout cols={item.attributes.cols} title={item.attributes.name}>
      <p>{item.attributes.value === 'true' ? l('accepted') : l('not accepted')}</p>
      {Component}
    </Layout>
  )
}

export function ShowValue ({item, Component}) {
  return (
    <Layout cols={item.attributes.cols} title={item.attributes.label}>
      <p>{item.attributes.value}</p>
      {Component}
    </Layout>
  )
}

export function ShowHTML ({item, Component}) {
  return (
    <Layout cols={item.attributes.cols} title={item.attributes.label}>
      <p dangerouslySetInnerHTML={{__html: item.attributes.value}}></p>
      {Component}
    </Layout>
  )
}

export function ShowMultiple ({item, Component}) {
  return (
    <Layout cols={item.attributes.cols} title={item.attributes.label}>
      <p>{item.attributes.value.join(', ')}</p>
      {Component}
    </Layout>
  )
}

export function ShowItemValue ({item, Component}) {
  const {items, value} = item.attributes
  const valueToShow = Object.values(items).find(i => i.value === value)

  return (
    <Layout cols={item.attributes.cols} title={item.attributes.label}>
      <p>{valueToShow?.label}</p>
      {Component}
    </Layout>
  )
}

export function ShowImage ({item, Component}) {
  return (
    <Layout cols={item.attributes.cols} title={item.attributes.label}>
      <img src={item.attributes.value} alt='selected' className='max-w-sm' />
      {Component}
    </Layout>
  )
}
