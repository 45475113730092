import {Link} from 'react-router-dom';
import CheckRole from '../../../components/CheckRole';
import DangerButton from '../../../components/DangerButton'

export function ActionsColumn ({rowData, handleDelete}) {
  return (
    <>
      <CheckRole has='view_clinic_cases'>
        <Link
          to={`/clinic-cases/${rowData._id}`}
          className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
        >
          <img className='w-[25px]' src='/img/view.svg' alt='' />
        </Link>
      </CheckRole>
      <CheckRole has='delete_clinic_cases'>
        <DangerButton
          onClick={() => handleDelete(rowData._id)}
        />
      </CheckRole>
    </>
  )
}