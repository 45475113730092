import Fetch from "./Fetch"

class Classifier {
  fetch = null

  static {
    this.fetch = new Fetch()
  }

  static get(type) {
    this.fetch.endpoint = 'classifiers'
    this.fetch.method = 'GET'
    type !== null && this.fetch.queries.set('type', type)
    return this.fetch.performRequest()
  }

  static create (data) {
    this.fetch.endpoint = 'classifiers'
    this.fetch.method = 'POST'
    this.fetch.body = data
    return this.fetch.performRequest() 
  }
}

export default Classifier