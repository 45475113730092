import React from 'react'
import Product from '../models/Product'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import useLocale from './useLocale'
import { useNavigate } from 'react-router-dom'

function useProduct (initData = null, productData = null) {
  const { product: id } = useParams()
  const { l } = useLocale()
  const [product, setProduct] = React.useState({})
  const [productInfo, setProductInfo] = React.useState(productData || initData)
  const navigate = useNavigate()

  const getProduct = React.useCallback(() => {
    Product.show(id)
      .then(res => setProduct(res))
      .catch(err => toast.error(l('general_error')))
  }, [id, l])

  const handleSave = () => {
    console.log(productInfo)
    if (productInfo?._id) {
      Product.update(productInfo._id, productInfo)
        .then(res => toast.success(l('product_updated')))
        .catch(() => toast.error(l('general_error')))
      return
    }

    Product.save(productInfo)
      .then(res => {
        toast.success(l('product_saved'))
        navigate('/products')
      })
      .catch(() => toast.error(l('general_error')))
  }

  const handleChange = (e, type = null) => {
    const { name, value } = e.target
    const aux = productInfo
    if (type)
      aux[type][name] = value
    else
      aux[name] = value
    setProductInfo({ ...aux })
  }

  const handleChangeTag = (tags, type = null) => {
    const aux = productInfo
    aux[type].tags = tags
    setProductInfo({ ...aux })
  }

  const handleCheck = (e, type = null, subType = null) => {
    const { name, checked } = e.target
    const aux = productInfo

    if (checked === true && subType !== null) {
      aux[type][subType].push(name)
    } else if (subType !== null) {
      const index = aux[type][subType].indexOf(name)
      aux[type][subType].splice(index, 1)
    } else {
      aux[type][name] = checked
    }

    setProductInfo({ ...aux })
  }

  const handleSetImage = images => {
    const aux = productInfo

    aux.basic.images.push(...images.map((image, idx) => ({
      url: image,
      isCover: !aux.basic.images.some(image => image.isCover === true) && idx === 0,
      description: null
    })))

    setProductInfo({ ...aux })
  }

  const updateImage = ({ index, description, isCover }) => {
    const aux = productInfo

    const image = aux.basic.images[index]
    image.description = description

    if (image.isCover === false && isCover === true) {
      const indexOf = aux.basic.images.map(image => image.isCover).indexOf(true)
      if (indexOf !== -1) aux.basic.images[indexOf].isCover = false
      image.isCover = true
    }

    aux.basic.images[index] = image
    setProductInfo({ ...aux })
  }

  const handleRemoveImage = (idx) => {
    const aux = productInfo
    aux.basic.images.splice(idx, 1)
    setProductInfo({ ...aux })
  }

  const handleSaveFile = (fileData, type = null) => {
    const aux = productInfo
    aux[type].attachedFiles.push(fileData)
    setProductInfo({ ...aux })
  }

  const handleRemoveFile = (index, type = null) => {
    const aux = productInfo
    aux[type].attachedFiles.splice(index, 1)
    setProductInfo({ ...aux })
  }

  const handleEditFile = (index, fileData, type = null) => {
    const aux = productInfo
    aux[type].attachedFiles[index].title = fileData.title
    aux[type].attachedFiles[index].description = fileData.description
    setProductInfo({ ...aux })
  }

  const handleAddAttribute = (key, value) => {
    const aux = productInfo
    aux.attributes[key] = value
    setProductInfo({ ...aux })
  }

  const handleRemoveAttribute = key => {
    const aux = productInfo
    delete aux.attributes[key]
    setProductInfo({ ...aux })
  }

  const productLoaded = Object.keys(product).length > 0

  return {
    product,
    productLoaded,
    id,
    handleChange,
    productInfo,
    handleCheck,
    handleSetImage,
    updateImage,
    handleSaveFile,
    handleRemoveFile,
    handleEditFile,
    handleAddAttribute,
    handleRemoveAttribute,
    handleRemoveImage,
    handleSave,
    handleChangeTag,
    getProduct
  }
}

export default useProduct