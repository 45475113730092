import {Routes, Route} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import Entrypoint from './pages/Entrypoint'
import Install from './pages/Install'
import Dashboard from './pages/Dashboard'
import Protected from './components/Protected'
import FormsPage from './pages/Form'
import FormPage from './pages/Form/show'
import {AppProvider} from './contexts/AppContext'
import ManageUsers from './pages/ManageUsers'
import Show from './pages/ManageUsers/show'
import RuleSets from './pages/Rulesets'
import ShowRuleSet from './pages/Rulesets/show'
import StatesPage from './pages/States'
import RolesAndPermissions from './pages/RolesAndPermissions'
import React from 'react'
import Layout from './components/Layout'
import Create from './pages/ManageUsers/create'
import LegalTextsList from './pages/LegalTexts'
import ShowLegalText from './pages/LegalTexts/show'
import EmailsPage from './pages/Emails'
import ShowEmail from './pages/Emails/show'
import ClinicCases from './pages/ClinicCases'
import ShowClinicCase from './pages/ClinicCases/show'
import AnswerForm from './pages/Form/answer'
import Queues from './pages/Queues'
import Configuration from './pages/Configuration'
import ShowConfig from './pages/Configuration/show'
import RegisterCodes from './pages/Codes'
import Logs from './pages/Logs'
import Origins from './pages/Origins'
import Products from './pages/Products'
import CreateProduct from './pages/Products/create'
import ShowProduct from './pages/Products/show'
import AnswerClinicCases from './pages/AnswerClinicCases'
import Answer from './pages/AnswerClinicCases/answer'
import UserPointsPage from './pages/ManageUsers/points'
import ImageGallery from './pages/ImageGallery'
import ViewUpload from './pages/ImageGallery/view'
import BooksPage from './pages/Books'
import CreateBookPage from './pages/Books/create'
import ViewBookPage from './pages/Books/view'
import TestPage from './pages/TestPage'
import ContactsPage from './pages/Contacts'
import ShowContact from './pages/Contacts/show'

function App () {
  return (
    <>
      <AppProvider>
        <ToastContainer
          position='top-center'
          hideProgressBar={false}
          closeOnClick
          draggable
          theme='dark'
        />
        <Routes>
          <Route path='/' element={<Entrypoint />} />
          <Route path='/install' element={<Install />} />
          <Route
            path='/dashboard'
            element={
              <Layout>
                <Protected>
                  <Dashboard />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/configuration'
            element={
              <Layout>
                <Protected has='list_config'>
                  <Configuration />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/configuration/:id'
            element={
              <Layout>
                <Protected has='view_config'>
                  <ShowConfig />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/form'
            element={
              <Layout>
                <Protected has='list_forms'>
                  <FormsPage />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/form/:form'
            element={
              <Layout>
                <Protected has='view_forms'>
                  <FormPage />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/form/:form/answer'
            element={
              <Layout>
                <Protected has='answer_forms'>
                  <AnswerForm />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/users'
            element={
              <Layout>
                <Protected has='list_users'>
                  <ManageUsers />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/users/:id'
            element={
              <Layout>
                <Protected has='view_users'>
                  <Show />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/users/:id/points'
            element={
              <Layout>
                <Protected has='view_users_points'>
                  <UserPointsPage />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/users/create'
            element={
              <Layout>
                <Protected has='add_users'>
                  <Create />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/rulesets'
            element={
              <Layout>
                <Protected has='list_rulesets'>
                  <RuleSets />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/rulesets/:id'
            element={
              <Layout>
                <Protected has='view_rulesets'>
                  <ShowRuleSet />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/states'
            element={
              <Layout>
                <Protected has='list_states'>
                  <StatesPage />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/roles'
            element={
              <Layout>
                <Protected has='list_roles'>
                  <RolesAndPermissions />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/legal-texts'
            element={
              <Layout>
                <Protected has='list_legal_texts'>
                  <LegalTextsList />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/legal-texts/:id'
            element={
              <Layout>
                <Protected has='view_legal_texts'>
                  <ShowLegalText />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/emails'
            element={
              <Layout>
                <Protected has='list_emails'>
                  <EmailsPage />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/emails/:id'
            element={
              <Layout>
                <Protected has='view_emails'>
                  <ShowEmail />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/clinic-cases'
            element={
              <Layout>
                <Protected has='list_clinic_cases'>
                  <ClinicCases />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/clinic-cases/:id'
            element={
              <Layout>
                <Protected has='view_clinic_cases'>
                  <ShowClinicCase />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/queues'
            element={
              <Layout>
                <Protected has='list_queue_items'>
                  <Queues />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/codes'
            element={
              <Layout>
                <Protected has='list_codes'>
                  <RegisterCodes />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/logs'
            element={
              <Layout>
                <Protected has='list_logs'>
                  <Logs />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/origins'
            element={
              <Layout>
                <Protected has='list_origins'>
                  <Origins />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/products'
            element={
              <Layout>
                <Protected has="list_products">
                  <Products />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/products/create'
            element={
              <Layout>
                <Protected has="add_products">
                  <CreateProduct />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/products/:product'
            element={
              <Layout>
                <Protected has="view_products">
                  <ShowProduct />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/answer-clinic-cases'
            element={
              <Layout>
                <Protected has="answer_clinic_cases">
                  <AnswerClinicCases />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/answer-clinic-cases/:id'
            element={
              <Layout>
                <Protected has="answer_clinic_cases">
                  <Answer />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/image-gallery'
            element={
              <Layout>
                <Protected has="list_images_on_gallery">
                  <ImageGallery />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/image-gallery/:uuid'
            element={
              <Layout>
                <Protected has="view_images_on_gallery">
                  <ViewUpload />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/books'
            element={
              <Layout>
                <Protected has="list_books">
                  <BooksPage />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/books/create'
            element={
              <Layout>
                <Protected has="add_books">
                  <CreateBookPage />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/books/:id'
            element={
              <Layout>
                <Protected has="view_books">
                  <ViewBookPage />
                </Protected>
              </Layout>
            }
          />
          <Route path='/test-page' element={
            <Layout>
              <Protected>
                <TestPage />
              </Protected>
            </Layout>
          } />
          <Route
            path='/consultations'
            element={
              <Layout>
                <Protected has="list_contacts">
                  <ContactsPage />
                </Protected>
              </Layout>
            }
          />
          <Route
            path='/consultations/:id'
            element={
              <Layout>
                <Protected has="view_contacts">
                  <ShowContact />
                </Protected>
              </Layout>
            }
          />
        </Routes>
      </AppProvider>
    </>
  )
}

export default App
