import React from 'react'
import { PageHeading } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import Card from '../../components/Card'
import useForms from '../../hooks/useForms'
import Modal from '../../components/Modal'
import { Input } from '../../components/FormComponents'
import CheckRole from '../../components/CheckRole'
import Button from '../../components/Button'
import DataGridView from '../../components/DataGridView'
import ActionColumn from './components/ActionColumn'

function FormsPage() {
	const { l } = useLocale()
	const {
		forms,
		handleDelete,
		handleAdd,
		nameRef,
		openModal,
		closeModal,
		showModal,
    getForms
  } = useForms({requestOnMount: false})

  const columns = [
    {
      header: l('id'),
      accessor: '_id'
    },
    {
      header: l('name'),
      accessor: 'name',
      filtrable: true,
    },
    {
      header: l('actions'),
      component: ({rowData, page, limit, filters}) => <ActionColumn handleDelete={(id) => handleDelete(id, page, limit, filters)} rowData={rowData} />,
      cellClass: 'flex gap-2 justify-center'
    }
  ]

	return (
		<>
			<PageHeading title={l('form')} image='/img/form.svg'>
				<CheckRole has={'add_forms'}>
					<Button onClick={openModal}>{l('add')}</Button>
				</CheckRole>
			</PageHeading>
			<Card>
        <DataGridView columns={columns} data={forms.docs} getData={getForms} totalPages={forms.totalPages} />
			</Card>

			<Modal show={showModal} onClose={closeModal}>
				<Input label={l('name')} reference={nameRef} />
				<Button onClick={handleAdd} type='secondary' full>
					{l('add')}
				</Button>
			</Modal>
		</>
	)
}

export default FormsPage
