import React from 'react'
import { componentsMap } from './components'
import useModal from '../../hooks/useModal'
import Button from '../../components/Button'
import useLocale from '../../hooks/useLocale'
import AnswerQuestions from '../AnswerQuestions'

function ViewClinicCase ({ clinicCase, withAnswers }) {
  const questionsModal = useModal()
  const itemRef = React.useRef(null)
  const { l } = useLocale()

  function handleOpen (item) {
    console.log(item)
    itemRef.current = item
    questionsModal.openModal()
  }

  return (
    <div className='mb-5 grid grid-cols-12'>
      {Object.keys(clinicCase.items).map(key => {
        const element = clinicCase.items[key]
        const hasQuestions = element.attributes.has_questions

        return <div style={{ gridColumn: `span ${element.attributes.cols || 12} / span ${element.attributes.cols || 12}` }}>
          {React.createElement(componentsMap[element.component], {
            item: element,
            isAnswersActive: withAnswers,
            handleOpen: handleOpen
          })}
          {(withAnswers && hasQuestions) && (
            <>
              <Button type='warning' onClick={() => handleOpen(element)}>
                {l('answer_questions')}
              </Button>
            </>
          )}
        </div>
      })}

      {withAnswers && (
        <>
          <AnswerQuestions item={itemRef.current} id={clinicCase._id} modal={questionsModal} questionsRef={itemRef.current?.attributes.questions} />
        </>
      )}
    </div>
  )
}

export default ViewClinicCase
