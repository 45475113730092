import React from 'react'
import Classifier from '../models/Classifier'

function useClassifiers(type) {
  const [classifiers, setClassifiers] = React.useState([])

  React.useEffect(() => {
    if (!type) return
    get()
  }, [type])

  function get() {
    Classifier.get(type)
      .then(res => setClassifiers(res))
  }
  return {
    classifiers,
  }
}

export default useClassifiers