import React from "react";
import Button from '../Button';
import ClinicCase from '../../models/ClinicCase'
// import {Input} from '../FormComponents';

export const TransferList = ({selected, setSelected}) => {
  // const [search, setSearch] = React.useState('');
  const [available, setAvailable] = React.useState([]);
  const [checkedAvailable, setCheckedAvailable] = React.useState([]);
  const [checkedSelected, setCheckedSelected] = React.useState([]);

  const handleFetch = React.useCallback(async function () {
    const data = await ClinicCase.get()
    setAvailable(data.docs);
  }, []);

  React.useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  const handleTransferToSelected = () => {
    setSelected([...selected, ...checkedAvailable.map(cheked => cheked._id)]);
    setCheckedAvailable([]);
  };

  const handleTransferToAvailable = () => {
    const newSelected = selected.filter(item => !checkedSelected.some(checked => checked._id === item));

    const newAvailable = available
      .filter(item => !checkedSelected.some(checked => checked._id === item._id))
      .concat(checkedSelected);

    setSelected(newSelected);
    setAvailable(newAvailable);
    setCheckedSelected([]);
  };

  return (
    <>
      <div className="flex justify-center">
        <div className="flex-1 p-4 border border-primary rounded-md max-h-96 overflow-y-auto">
          <h2 className="text-lg font-bold text-primary">Available Objects</h2>
          {/* <Input onChange={(e) => setSearch(e.currentTarget.value)} name="search" placeholder='Search...' /> */}
          <ul className=''>
            {available.filter(item => !selected.includes(item._id)).map(item => (
              <li key={item._id} className='text-primary'>
                <input
                  type="checkbox"
                  checked={checkedAvailable.some((available) => item._id === available._id)}
                  onChange={() => {
                    setCheckedAvailable(prev =>
                      prev.includes(item) ? prev.filter(i => i !== item) : [...prev, item]
                    );
                  }}
                />
                {item.title}
              </li>
            ))}
          </ul>
        </div>

        <div className="flex flex-col items-center justify-center p-4 gap-4">
          <Button onClick={handleTransferToSelected}>
            {'>>'}
          </Button>
          <Button onClick={handleTransferToAvailable}>
            {'<<'}
          </Button>
        </div>

        <div className="flex-1 p-4  border border-primary rounded-md overflow-y-auto">
          <h2 className="text-lg font-bold text-primary">Selected Objects</h2>
          <ul>
            {available.filter(item => selected.includes(item._id)).map(item => (
              <li key={item._id} className='text-primary'>
                <input
                  type="checkbox"
                  checked={checkedSelected.some((selected) => item._id === selected._id)}
                  onChange={() => {
                    setCheckedSelected(prev =>
                      prev.includes(item) ? prev.filter(i => i !== item) : [...prev, item]
                    );
                  }}
                />
                {item.title}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default TransferList;