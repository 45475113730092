import React from 'react'
import useLocale from '../../hooks/useLocale'
import { H3 } from '../Headings'
import useUsers from '../../hooks/useUsers'
import { Input } from '../FormComponents'
import Button from '../Button'

function ReassignClinicCaseModal ({ reassign }) {
  const { l } = useLocale()
  const { users, search, setSearch } = useUsers(false)
  const [selectedUser, setSelectedUser] = React.useState('')

  function handleChange (e) {
    const { value } = e.currentTarget
    setSearch(value)
  }

  function handleClick () {
    reassign(selectedUser)
    setSearch('')
    setSelectedUser('')
  }

  return (
    <>
      <H3>{l('reassign_case')}</H3>
      <Input value={search} onChange={handleChange} placeholder={l('search_user')} />
      <div className='flex flex-col gap-3 mb-3'>
        {users.map(user => <>
          <div
            key={user._id}
            className={`px-3 py-2 border-gray-600 border rounded-md cursor-pointer ${selectedUser === user._id ? 'bg-green-400' : ''}`}
            onClick={() => setSelectedUser(user._id)}
          >
            {user.name} ({user._id})
          </div>
        </>
        )}
      </div>
      <Button type="secondary" onClick={handleClick} full>{l('assign')}</Button>
    </>
  )
}

export default ReassignClinicCaseModal