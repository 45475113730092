import React from 'react'
import useLocale from '../hooks/useLocale'
import User from '../models/User'
import { toast } from 'react-toastify'

function useUsers(paginate = true) {
  const [ users, setUsers ] = React.useState([])
  const [ search, setSearch ] = React.useState('')
  const [ page, setPage ] = React.useState(1)
  const { l } = useLocale()

  const getUsers = () => {
    User.get(search, page, paginate)
      .then(res => {
        if (User.fetch.status !== 200) {
          return toast.error(l('general_error'))
        }
        setUsers(res)
      })
      .catch(err => toast.error(l('general_error')))
  }

  React.useEffect(() => {
    getUsers()
  }, [ search, page ])

  const handleDelete = id => {
    User.delete(id)
      .then(res => getUsers())
      .catch(err => toast.error(l('general_error')))
  }

  const exportXlsx = () => {
    User.export().then(blob => {
			var a = document.createElement('a')
			a.href = window.URL.createObjectURL(blob)
			a.download = `users.xlsx`
			a.click()
		})
  }

  return {
    users,
    setSearch,
    search,
    handleDelete,
    setPage,
    exportXlsx
  }
}

export default useUsers
