import React from 'react'
import { PageHeading } from '../../components/Headings'
import Card from '../../components/Card'
import { Input } from '../../components/FormComponents'
import { Link } from 'react-router-dom'
import CheckRole from '../../components/CheckRole'
import useUsers from '../../hooks/useUsers'
import useLocale from '../../hooks/useLocale'
import DangerButton from '../../components/DangerButton'
import Paginator from '../../components/Paginator'
import Button from '../../components/Button'

function ManageUsers() {
	const { users, setSearch, search, handleDelete, setPage, exportXlsx } = useUsers()
	const { l } = useLocale()

	return (
		<>
			<PageHeading image='/img/users.svg' title={l('manage_users')}>
				<CheckRole has='add_users'>
					<Link
						className='bg-gray-500 px-2 py-1 rounded-md hover:bg-gray-400 ease-in-out duration-200'
						to='/users/create'
					>
						{l('add')}
					</Link>
				</CheckRole>
        <CheckRole has={'export_users'}>
          <Button onClick={exportXlsx} type='warning'>{l('export')}</Button>
        </CheckRole>
			</PageHeading>
			<Card>
				<div className='mb-4'>
					<Input
						className='w-64'
						value={search}
						onChange={e => setSearch(e.target.value)}
						placeholder={l('search')}
					/>
				</div>
				<table className='w-full'>
					<thead>
						<tr className='text-left border-b border-gray-600'>
							<th>{l('name')}</th>
							<th>{l('email')}</th>
							<th>{l('actions')}</th>
						</tr>
					</thead>
					<tbody>
						{users.docs?.map(user => (
							<tr key={user._id}>
								<td>{user.name}</td>
								<td>{user.email}</td>
								<td className='flex gap-3'>
									<CheckRole has='view_users'>
										<Link
											to={`/users/${user._id}`}
											className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
										>
											<img className='w-[25px]' src='/img/view.svg' alt='' />
										</Link>
									</CheckRole>
									<CheckRole has='delete_users'>
										<DangerButton onClick={() => handleDelete(user._id)} />
									</CheckRole>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<Paginator meta={users} setPage={setPage} />
			</Card>
		</>
	)
}

export default ManageUsers
