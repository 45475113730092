import React from 'react'
import Card from '../../components/Card'
import {H1} from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import componentsMap from '../../config/answerComponents'
import useAnswer from '../../hooks/useAnswer'
import Divider from '../../components/Divider'
import Button from '../../components/Button'
import {textColor} from '../../utils'
import useQuestions from '../../hooks/useQuestions'
import FormQuestions from '../../components/FormQuestions'


function AnswerForm () {
  const {l} = useLocale()
  const {
    form,
    handleCheckboxChange,
    handleInputChange,
    handleAction,
    errors,
    handleAddRow,
    handleDeleteRow,
    handleTagChange,
    setForm,
    handleFileSelect,
    handleMetaChange,
    handlePushGalleryFile,
    handleAddGalleryItem,
    files,
    handleDeleteFile,
    handleSetMetaField,
    handleSetConsentDocument,
    handleOptinChange
  } = useAnswer()
  const {
    handleViewQuestions,
    ...restOfQuestionsLogic
  } = useQuestions({form, setForm})

  return (
    <>
      <H1>
        <img src='/img/form.svg' className='w-[35px]' alt='' />
        {l('answer_form')} {form.name}
      </H1>
      <Card>
        <div className='grid grid-cols-12 gap-3'>
          {Object.keys(form.items).map(key => {
            const item = form.items[key]
            const error = errors.find(
              error =>
                (error.key === item.attributes?.label || error.key === item._id) && error.passed === false
            )
            return (
              <div style={{gridColumn: `span ${item.attributes.cols || 12} / span ${item.attributes.cols || 12}`}} key={key}>
                {React.createElement(componentsMap[item.component], {
                  item,
                  handleInputChange,
                  handleCheckboxChange,
                  index: key,
                  handleAddRow,
                  handleDeleteRow,
                  errors,
                  handleTagChange,
                  handleViewQuestions,
                  handleFileSelect,
                  handleMetaChange,
                  handlePushGalleryFile,
                  handleAddGalleryItem,
                  files,
                  handleSetConsentDocument,
                  handleDeleteFile,
                  handleSetMetaField,
                  handleOptinChange,
                  ...restOfQuestionsLogic
                })}
                {error && error.message.map(message => <p className='text-sm text-red-500'>{l(message)}</p>)}
                {item.attributes.has_questions && (
                  <Button type='warning' onClick={() => handleViewQuestions(item, key)}>
                    {l('view_questions')}
                  </Button>
                )}
              </div>
            )
          })}
        </div>
        <Divider />
      </Card>
      <div className={'flex gap-3'}>
        {form.actions?.map((rule, idx) => (
          <Button
            key={rule._id}
            style={{background: rule.color, color: textColor(rule.color)}}
            onClick={() => handleAction(rule)}
          >
            {rule.label}
          </Button>
        ))}
      </div>

      <FormQuestions {...restOfQuestionsLogic} />
    </>
  )
}

export default AnswerForm
