import { H2 } from '../Headings'
import React from 'react'
import useLocale from '../../hooks/useLocale'
import useRoles from '../../hooks/useRoles'
import useEmails from '../../hooks/useEmails'
import { Checkbox, Select } from '../FormComponents'

function UserConfig({ config, handleChange, handleCheckboxChange }) {
	const { l } = useLocale()
	const { roles } = useRoles(false)
	const { mails } = useEmails(false)

	return (
		<>
			<H2>
				<img src='/img/users.svg' className={'w-[25px]'} alt='' />{' '}
				{l('user_config')}
			</H2>
			<div className={'grid gird-cols-1 md:grid-cols-3 gap-3'}>
				<Select
					options={roles}
					label={l('default_role')}
					value={config.user?.defaultRole}
					valueKey={'_id'}
					labelKey={'name'}
					onChange={handleChange}
					name={'defaultRole'}
				/>
				<Select
					options={mails}
					label={l('welcome_email')}
					value={config.user?.welcomeEmail}
					valueKey={'_id'}
					labelKey={'name'}
					onChange={handleChange}
					name={'welcomeEmail'}
				/>
				<Select
					options={mails}
					label={l('remember_password_email')}
					value={config.user?.rememberPasswordEmail}
					valueKey={'_id'}
					labelKey={'name'}
					onChange={handleChange}
					name={'rememberPasswordEmail'}
				/>
			</div>
			<Checkbox
				label={l('register_with_codes')}
				onChange={handleCheckboxChange}
				checked={config.user?.registerWithCodes}
				name={'registerWithCodes'}
			/>
		</>
	)
}

export default UserConfig
