import React from 'react'
import Card from '../../components/Card'
import { H2, PageHeading } from '../../components/Headings'
import useLocale from '../../hooks/useLocale'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { Input } from '../../components/FormComponents'
import CheckRole from '../../components/CheckRole'
import useConfigurations from '../../hooks/useConfigurations'
import DataGridView from '../../components/DataGridView'
import ActionsColumn from './component/ActionsColumn'

function Config() {
	const { l } = useLocale()
	const {
		openModal,
		configSets,
		handleSave,
		handleDelete,
		nameRef,
		closeModal,
		showModal,
    getConfigs
  } = useConfigurations(true, false)

  const columns = [
    {
      header: l('id'),
      accessor: '_id'
    },
    {
      header: l('name'),
      accessor: 'name',
      filtrable: true
    },
    {
      header: l('actions'),
      component: ({rowData, limit, page, filters}) => <ActionsColumn rowData={rowData} handleDelete={(id) => handleDelete(id, page, filters, limit)} />,
      cellClass: 'flex gap-2 justify-center'
    }
  ]

	return (
		<>
			<PageHeading image='/img/cog.svg' title={l('config')}>
				<CheckRole has={'add_config'}>
					<Button onClick={openModal}>{l('add')}</Button>
				</CheckRole>
			</PageHeading>
			<Card>
        <DataGridView columns={columns} data={configSets.docs} getData={getConfigs} totalPages={configSets.totalPages} />
			</Card>
			<Modal show={showModal} onClose={closeModal}>
				<H2>{l('add_config')}</H2>
				<form onSubmit={handleSave}>
					<Input label={l('name')} reference={nameRef} />
					<Button full type={'secondary'}>
						{l('save')}
					</Button>
				</form>
			</Modal>
		</>
	)
}

export default Config
