import React from 'react'
import CheckRole from '../../../components/CheckRole'
import {Link} from 'react-router-dom'
import DangerButton from '../../../components/DangerButton'

function ActionsColumn ({rowData, handleDelete}) {
  return (
    <>
      <CheckRole has={'view_config'}>
        <Link
          to={`/configuration/${rowData._id}`}
          className='bg-black hover:bg-gray-700 px-2 py-1 rounded-md ease-in-out duration-200'
        >
          <img className='w-[25px]' src='/img/view.svg' alt='' />
        </Link>
      </CheckRole>
      <CheckRole has={'delete_config'}>
        <DangerButton onClick={() => handleDelete(rowData._id)}>
          <img src='/img/trash.svg' className={'w-[20px]'} alt='' />
        </DangerButton>
      </CheckRole>
    </>
  )
}

export default ActionsColumn