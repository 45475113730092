import React from 'react'
import { Input } from '../FormComponents'
import Modal from '../Modal'
import Divider from '../Divider'
import Button from '../Button'
import { H2 } from '../Headings'
import useLocale from '../../hooks/useLocale'
import Classifier from '../../models/Classifier'
import {toast} from 'react-toastify'
import useClassifiers from '../../hooks/useClassifiers'

const typeMap = {
  quality: 'qualities',
  difficulty: 'difficulties'
}

function ClassifiersForm ({ showModal, closeModal, typeRef, addClassifier }) {
  const { l } = useLocale()
  const nameRef = React.useRef(null)
  const pointsRef = React.useRef(null)
  const selectRef = React.useRef(null)
  const {classifiers} = useClassifiers(typeRef.current)

  function handleSubmitAdd (e) {
    e.preventDefault()
    Classifier.create({
      label: nameRef.current.value,
      type: typeRef.current,
      points: pointsRef.current.value
    }).then(res => {
      toast.success(l('classifier_added'))
      nameRef.current.value = ''
      pointsRef.current.value = 0
      addClassifier(typeMap[typeRef.current], res._id)
      closeModal()
    })
  }

  function handleSubmitSelect (e) {
    e.preventDefault()
    closeModal()
    addClassifier(typeMap[typeRef.current], selectRef.current.value)
    selectRef.current.value = ''
  }

  return (
    <>
      <Modal show={showModal} onClose={closeModal}>
        <H2>{l('create_new')} {l(typeRef.current)}</H2>
        <form onSubmit={handleSubmitAdd}>
          <Input label={l('label')} reference={nameRef} />
          <Input type="number" label={l('points')} reference={pointsRef} />
          <Button full type='secondary'>
            {l('save')}
          </Button>
        </form>
        <Divider />
        <H2>{l('or_select_one')}</H2>
        <form onSubmit={handleSubmitSelect}>
          <select className='border border-gray-300 rounded-md px-2 py-1 mb-2 w-full' ref={selectRef}>
            <option value=""></option>
            {classifiers.map(classifier => <option value={classifier._id} key={classifier._id}>{classifier.label} ({classifier.points} pts.)</option>)}
          </select>
          <Button full type="secondary">
            {l('add')}
          </Button>
        </form>
      </Modal>
    </>
  )
}

export default ClassifiersForm