import React from 'react'
import useLocale from '../../hooks/useLocale'
import useModal from '../../hooks/useModal'
import Button from '../Button'
import { H2 } from '../Headings'
import Modal from '../Modal'

function DangerButton ({ onClick, className }) {
  const { showModal, openModal, closeModal } = useModal()
  const { l } = useLocale()

  const handleAccept = () => {
    onClick()
    closeModal()
  }

  return (
    <>
      <Button onClick={openModal} className={className} type='danger'>
        <img src='/img/trash.svg' alt='' className='w-full max-w-[20px]' />
      </Button>
      <Modal show={showModal} onClose={closeModal}>
        <H2>{l('sure')}</H2>
        <p>{l('sure_message')}</p>
        <p>{l('irreversible_action')}</p>
        <p>{l('proceed_question')}</p>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mt-4'>
          <Button onClick={handleAccept} type='danger'>
            {l('yes_i_know')}
          </Button>
          <Button onClick={closeModal} type='secondary'>
            {l('cancel')}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default DangerButton
